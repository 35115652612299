<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="$can('create', 'budget_expense')"
                variant="primary"
                @click="
                  () => {
                    this.$router.push(
                      '/budget-expense/add/' + this.budget_main_id
                    );
                  }
                "
              >
                <span class="text-nowrap">Add Expense</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        bordered
        v-if="$can('read', 'budget_expense')"
        class="position-relative"
        :items="expense_list_data"
        responsive
        :fields="tableColumns"
        primary-key="expense_id"
        show-empty
        empty-text="No matching records found"
        thead-tr-class="hi"
      >
        <template #cell(invoice_date)="data">
          {{
            data.item.invoice_date
              ? moment(data.item.invoice_date).format("DD/MM/YYYY")
              : "-"
          }}
        </template>

        <template #cell(attachment)="data">
          <span v-if="data.item.attachment">
            <a :href="BASE_URL + data.item.attachment" target="_blank">View</a>
          </span>
          <span v-else>N/A</span>
        </template>
      </b-table>
      <div class="mx-2 mb-2" v-if="$can('read', 'budget_expense')">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRows"
              :per-page="pagination.rowsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  VBPopover,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import { GetBudget } from "@/apiServices/BudgetServices";
import { GetExpenseByMainID } from "@/apiServices/BudgetExpenseServices";
import moment from "moment";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
    ToastificationContentVue,
  },

  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      moment: moment,
      BASE_URL: process.env.VUE_APP_FILESURL,
      budget_main_id: null,
      expense_list_data: [],
      pagination: {
        currentPage: 0,
        totalRows: 1,
        rowsPerPage: 10,
        perPageOptions: [10, 25, 50, 100],
      },
      tableColumns: [
        { label: "Vendor", key: "vendor_name", sortable: true },
        { label: "Invoice Number", key: "invoice_number", sortable: true },
        { label: "Category", key: "category_name", sortable: true },
        { label: "Subcategory", key: "sub_category_name", sortable: true },
        { label: "Territory", key: "territory_name", sortable: true },
        { label: "Unit", key: "unit", sortable: true },
        { label: "Rate", key: "rate", sortable: true },
        { label: "Amount", key: "amount", sortable: true },
        { label: "Invoice Date", key: "invoice_date", sortable: true },
        { label: "Attachment", key: "attachment", sortable: true },
        { label: "Explanation", key: "explanation", sortable: true },
        // { key: "actions" },
      ],
      search: "",
    };
  },

  methods: {
    async getExpenseByMainID() {
      const response = await GetExpenseByMainID(this.budget_main_id);
      if (!response.data.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title:
              response.data.message || "Unable to load data, try again later!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
      this.expense_list_data = response.data.data;
    },
  },

  watch: {
    search() {
      this.getExpenseByMainID();
    },
  },

  beforeMount() {
    this.budget_main_id = this.$route.params.id;
    this.getExpenseByMainID();
  },
};
</script>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.per-page-selector {
  width: 90px;
}

.w-vendor {
  min-width: 300px;
}

.hi > th {
  min-width: 200px !important;
}
</style>
